import React from 'react'
import { Link, useParams } from 'react-router-dom'

/*

    In this page the student can view their class information

    they can 
    see their grade, 
    percent of completion, 
    go to the place to go through the class material,
    view resources such as games or videos    

*/
export default function ClassPage() {


  const { classID } = useParams()


  return (
    <div className='page' style={{padding: "20px"}}>
      
      {/* Class Title */}
      <div style={{padding: "20px"}}>{classID}</div>
      
      {/* Class Tiles */}

      {/* Completion (percent completion, grade, percentile, etc) */}
      <div className='smallTile'>
        <div >
          Completion  
        </div>
      </div>

      {/* Content (the chapters, sections, etc) */}
      <div className='smallTile'>
        <div>
          Content  
        </div>
      </div>

      {/* Resources (games, videos, books) */}
      <div className='smallTile'>
        <div>
          Resources  
        </div>
        <Link to={"/language-game/es"}>
          <button>Language Game</button>
        </Link>
      </div>

    </div>
  )
}