import { useEffect, useState } from "react";
import "./ImageUploader.css";
import ConfirmationBox from "../Misc/ConfirmationBox";
import { supabaseClient } from "../../Global/supabaseinit";

export default function ImageUploaderSingle({
    bucket = "image-bucket", // The Supabase bucket to store the image in
    table = "users", // The table to save the image data object into
    attribute = "image1", // The column to add the image data object to
    rowId, // The id of the row to add the image data to
    defaultValue, // defaultValue: {publicUrl: <viewable image url>, bucket: <bucket name>, }
    updateCallback = () => {}, // Displaying a message to show if the save was successful or if there was an error
    className,
    readOnly,
    style, 
    otherUpdateValues, 
}) {
    const [imageData, setImageData] = useState(defaultValue || null); // Single image data object
    const [showLargeImageDisplay, setShowLargeImageDisplay] = useState(false);
    const [userMessage, setUserMessage] = useState("");
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [pendingFile, setPendingFile] = useState(null); // Stores the file to upload after confirmation

    useEffect(() => {
        if (defaultValue) {
            setImageData(defaultValue);
        }
    }, [defaultValue]);

    // #region Uploading

    // When an image is dropped onto the box
    const handleDrop = async (event) => {
        event.preventDefault();

        if (!rowId) {
            console.log("Must have a valid item ID");
            setUserMessage("Must have a valid item ID to upload an image.");
            return;
        }

        const file = event.dataTransfer.files[0]; // Only allow one file to be uploaded
        if (!file) {
            setUserMessage("No file detected. Please try again.");
            return;
        }

        // If there's an existing image, show confirmation before replacing it
        if (imageData) {
            setPendingFile(file); // Store the file for later
            setShowDeleteConfirmation(true); // Show confirmation box
        } else {
            // No existing image, proceed with upload
            uploadAndSaveFile(file);
        }
    };

    // Upload a single file to Supabase
    const uploadFileToSupabase = async (file) => {
        if (!supabaseClient) {
            console.error("Please provide Supabase supabaseClient for image upload");
            return;
        }

        const fileStorageResponse = await supabaseClient.storage
            .from(bucket)
            .upload(file.name, file, { upsert: true });

        if (fileStorageResponse.error) {
            throw new Error(fileStorageResponse.error.message);
        }

        const publicUrlResponse = await supabaseClient.storage
            .from(bucket)
            .getPublicUrl(fileStorageResponse.data.path);

        return {
            bucket: bucket,
            publicUrl: publicUrlResponse.data.publicUrl,
            storageKey: fileStorageResponse.data.id,
        };
    };

    // Update the user's table with the new image data
    const updateRow = async (newFileData) => {
        if (!supabaseClient) {
            console.error("Please provide Supabase supabaseClient for image upload");
            return;
        }

        if (!rowId) {
            const updateResponse = await supabaseClient
                .from(table)
                .insert({ [attribute]: newFileData, ...otherUpdateValues })
                .select("*")
                .single();
            if (updateResponse?.error) {
                console.error(updateResponse.error)
            } else {
                updateCallback(updateResponse?.data[0])
            }
            return updateResponse
        }
        const updateResponse = await supabaseClient
            .from(table)
            .update({ [attribute]: newFileData })
            .eq("id", rowId)
            .select("*")
            .single();

        if (updateResponse.error) {
            throw new Error(updateResponse.error.message);
        }

        return updateResponse;
    };

    // Handle file upload and database update
    const uploadAndSaveFile = async (file) => {
        try {
            setUserMessage("Uploading...");
            updateCallback("Uploading");

            // Step 1: Upload file to Supabase
            const uploadedFileData = await uploadFileToSupabase(file);

            setUserMessage("Upload Complete. Saving...");
            updateCallback("Saving");

            // Step 2: Update the user's table with new file URL and data
            await updateRow(uploadedFileData);

            setUserMessage("Saved");
            updateCallback("Saved");

            // Step 3: Update the local state to display the image
            setImageData(uploadedFileData);
        } catch (err) {
            console.error("Error uploading file:", err);            
            setUserMessage("Error: " + err?.message);
            updateCallback("Error");
        }
    };

    const browseForImage = () => {
        // Create a hidden input element for file selection
        const inputElement = document.createElement("input");
        inputElement.type = "file";
        inputElement.accept = "image/*"; // Allow only image files

        // When the file is selected, handle the file
        inputElement.onchange = async (event) => {
            const file = event.target.files[0]; // Get the first selected file
            if (file) {
                try {
                    // If there's an existing image, show confirmation before replacing it
                    if (imageData) {
                        setPendingFile(file); // Store the file for later
                        setShowDeleteConfirmation(true); // Show confirmation box
                    } else {
                        // No existing image, proceed with upload
                        await uploadAndSaveFile(file);
                    }
                } catch (error) {
                    console.error("Error uploading file:", error);
                    setUserMessage("Error uploading file.");
                    updateCallback("Error");
                }
            }
        };

        // Programmatically click the input element to open the file browser
        inputElement.click();
    };

    // #endregion Uploading

    // #region Deletion

    // Delete the existing image
    const deleteImage = async () => {
        if (!supabaseClient || !imageData || !rowId) {
            console.error("Missing required data for deletion");
            setUserMessage("Error: Missing required data for deletion.");
            return;
        }

        try {
            setUserMessage("Deleting...");
            updateCallback("Deleting");

            // Step 1: Delete the image file from the Supabase bucket
            const { error } = await supabaseClient.storage
                .from(imageData.bucket)
                .remove([imageData.storageKey]);

            if (error) {
                console.error(error.message);
            }

            // Step 2: Update the table column to remove the image data
            const response = await supabaseClient
                .from(table)
                .update({ [attribute]: null })
                .eq("id", rowId)
                .select("*")
                .single();

            if (response.error) {
                console.error(response.error.message);
            }

            setUserMessage("Image deleted successfully.");
            updateCallback("Deleted");

            // Step 3: Clear the local state
            setImageData(null);

            // If there's a pending file, upload it after deletion
            if (pendingFile) {
                uploadAndSaveFile(pendingFile);
                setPendingFile(null); // Clear the pending file
            }
        } catch (err) {
            console.error("Error deleting image:", err);
            setUserMessage("Error deleting image.");
            updateCallback("Error");
        }
    };

    // #endregion Deletion

    return (
        <>
            <div
                className={`imageUploaderSB ${className}`}
                style={{
                    backgroundColor: !readOnly ? "rgba(224, 201, 234, 0.5)" : undefined,
                    ...style,
                }}
                onDragOver={(e) => e.preventDefault()}
                onDrop={handleDrop}
                onClick={() => {
                    if (!readOnly && !imageData) {
                        browseForImage(); // Open file selector if in edit mode and no image
                    }
                }}
            >
                <div className="imageUploaderRowId">{`Row ID: ${rowId}`}</div>
                

                {imageData ? (
                    <img
                        src={imageData?.publicUrl}
                        style={{ objectFit: "cover" }}
                        alt="Uploaded Image"
                    />
                ) : (
                    !readOnly && (
                        <div className="uploadPrompt">
                            Click to upload an image or drag and drop
                        </div>
                    )
                )}

                {!readOnly && (
                    <div className="imageBottomInfo">
                        {userMessage && <div className="userMessage">{userMessage}</div>}
                        {imageData ? (
                            <>
                                <div
                                    className="imageBottomButton"
                                    title={"Select Image"}
                                    onClick={(e) => {
                                        e.stopPropagation(); // Prevent triggering the parent onClick
                                        browseForImage();
                                    }}
                                >
                                    📁
                                </div>
                                <div
                                    title={"Delete Image"}
                                    onClick={(e) => {
                                        e.stopPropagation(); // Prevent triggering the parent onClick
                                        setShowDeleteConfirmation(true);
                                    }}
                                    className="imageBottomButton"
                                    style={{ left: "5px" }}
                                >
                                    🗑️
                                </div>
                            </>
                        ) : null}
                    </div>
                )}
            </div>

            {showDeleteConfirmation && (
                <ConfirmationBox
                    text={"Delete Image?"}
                    onCancel={() => setShowDeleteConfirmation(false)}
                    onConfirm={deleteImage}
                />
            )}
        </>
    );
}