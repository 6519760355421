import React, { useEffect, useState } from 'react'
import { supabaseClient } from '../../../../Global/supabaseinit'
import WordDisplaySupabase from './WordDisplaySupabase'
import WordDisplay from './WordDisplay'

export default function WordsDisplay({string, cleanText = (string)=>{return string}, close}) {
    const [wordsData, setWordsData] = useState([])
    const [wordsArray, setWordsArray] = useState([])

    useEffect(()=>{
        createArray()
    },[string])
    useEffect(()=>{
        loadWordsData()
    },[wordsArray])

    function createArray(){
        console.log("createArray: ", string)
        if(!string || typeof string !== "string") return
        console.log("createArray2: ", string)
        
        let cleanedString = cleanText(string)
        console.log("cleanedString: ", cleanedString)
        let tempWordsArray = cleanedString.split(" ")
        setWordsArray(tempWordsArray)
        console.log("tempWordsArray: ", tempWordsArray)

    }

    // Loads the word data from the database
    async function loadWordsData(){
        
        if(!wordsArray || ! Array.isArray(wordsArray)) return

        // Convert all words to lowercase for consistent database querying
        const lowercaseWordsArray = wordsArray.map(word => word.toLowerCase())
        
        // Set this object to the words
        let wordsDataObjectTemp = {}
        lowercaseWordsArray.forEach(word => {
            wordsDataObjectTemp[word] = {es: word}
        })

        setWordsData(Object.values(wordsDataObjectTemp))

        try{
            let wordDataReply = await supabaseClient
                .from("language_game_words")
                .select("*")
                .in("es", lowercaseWordsArray)                

            if(wordDataReply.error){
                console.error("wordDataReply.error: ", wordDataReply.error)
                return
            }

            wordDataReply.data.map(object  => {
                wordsDataObjectTemp[object.es] = object
            }) 

        }catch{
            console.error("fetch error ")            
        }

        setWordsData(Object.values(wordsDataObjectTemp))

    }
  return (
    <div className='window' style={{width: "95%", margin: "0 auto", left: "2.5%"}}>
        <div className='closeButton' onClick={close}>X</div>
        <div className='windowInner' style={{height: "100%", overflowY: "auto"}}>
            {wordsData.map(wordData => (
                <WordDisplay wordData={wordData}></WordDisplay>
            ))}
        </div>        
    </div>
  )
}