'use client'

import React from 'react'
import { useEffect, useRef, useState } from 'react'
import { supabaseClient } from '../../Global/supabaseinit'
import ImageUploaderSingle from './ImageUploaderSingle'

export default function InputSupabase({
  table,
  rowId,
  attribute,
  defaultValue,
  updateCallback = () => {},
  className = '',
  style = {}, 
  placeholder,
  integer = false,
  type = 'text',
  readOnly = false,
  hasViewMode,
  defaultEditMode,
  options = [],
  otherUpdateValues = {},
}) {
  const [value, setValue] = useState(defaultValue)
  const [editMode, setEditMode] = useState(defaultEditMode)
  const timeoutRef = useRef(null)

  // Update input value when defaultValue changes
  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])

  async function handleChange(e) {
    const newValue = e.target.value
    if (integer) {
      const parsedValue = parseInt(newValue, 10)
      if (!isNaN(parsedValue)) {
        setValue(parsedValue)
      }
    } else {
      setValue(newValue)
    }

    // Clear existing timeout if it exists
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }

    
    // Set new timeout to update database after 1 second of no typing
    timeoutRef.current = setTimeout(async () => {
    
      console.log(`updating ${table}.${attribute} to ${integer ? 'integer:' : ''}${newValue} for row ${rowId}`)

      // If the row id is undefined then we are inserting a new row
      if (rowId === undefined) {
        const { data, error } = await supabaseClient
          .from(table)
          .insert({ [attribute]: integer ? parseInt(newValue, 10) : newValue, ...otherUpdateValues })
          .select("*")
        if (error) {
          console.error(error)
        } else {
          // Call the update callback so the data updates in the parent component
          updateCallback(data[0])
        }
        // Return so it does not update the database again
        return
      }
      
      // 
      const { data, error } = await supabaseClient
        .from(table)
        // If the interger flag is on the db expects a number so converting it here
        .update({ [attribute]: integer ? parseInt(newValue, 10) : newValue })
        .eq('id', rowId)
        .select("*")
        .single()

      if (error) {
        console.error('Error updating value:', error)
        return
      }else{
        // Call the update callback so the data updates in the parent component
        updateCallback(data)
      }

      
    }, 1000)
  }

  // Cleanup timeout on unmount
  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [])

  if (type === 'textarea') {
    return (
      <>
      {hasViewMode ? 
        <div
          className={className}          
          style={{
            width: '100%',
            display: "block",  
            borderRadius: "10px",
            border: "1px solid #ddd",
            overflow: "hidden",
            marginBottom: "15px",
            position: "relative",
            backgroundColor: "orange",
            ...style,        
          }}
          title={"Click the edit icon to edit"}
        >
          <div
            onClick={()=>setEditMode(!editMode)}
            style={{
              width: '36px',
              height: '36px',
              lineHeight: '36px',
              position: "absolute",
              right: "0",
              top: "0",
              userSelect: "none",
              cursor: "pointer",
              backgroundColor: "#fff",
              textAlign: "center",
              zIndex: 1
            }}
            title={editMode ? "Done" : "Edit"}
          >
            {editMode ?  "✔" : "✏️" }
          </div>
          {editMode ? 
            <textarea
              defaultValue={defaultValue}
              placeholder={placeholder}
              onChange={handleChange}
              style={{
                width: '100%',
                minHeight: '100px',
                padding: '8px',
                paddingRight: '44px',
                border: 'none',
                resize: 'vertical',
                boxSizing: 'border-box'
              }}
              readOnly={readOnly}
            />
            :
            <div                                            
              style={{
                width: '100%',
                minHeight: '100px',
                padding: '8px',
                paddingRight: '44px',
                boxSizing: 'border-box',
                whiteSpace: 'pre-wrap',
                textAlign: 'left'
              }}
            >
              {value || <span style={{ 
                color: '#9ca3af', 
                userSelect: 'none'
              }}>{placeholder}</span>}
            </div>
          }
        </div>
        :
        <textarea
          defaultValue={defaultValue}
          placeholder={placeholder}
          onChange={handleChange}
          className={className}
          style={style}
          readOnly={readOnly}
        />
      }
      </>
    )
  }

  if (type === 'select') {
    return (
      <>
      {hasViewMode ? 
        <div
          className={className}          
          style={{
            width: '100%',
            display: "block",  
            borderRadius: "10px",
            border: "1px solid #ddd",
            overflow: "hidden",
            marginBottom: "15px",
            position: "relative",
            ...style,        
          }}
          title={"Click the edit icon to edit"}
        >
          <div
            onClick={()=>setEditMode(!editMode)}
            style={{
              width: '36px',
              height: '36px',
              lineHeight: '36px',
              position: "absolute",
              right: "0",
              top: "0",
              userSelect: "none",
              cursor: "pointer",
              backgroundColor: "#fff",
              textAlign: "center",
              zIndex: 1
            }}
            title={editMode ? "Done" : "Edit"}
          >
            {editMode ?  "✔" : "✏️" }
          </div>
          {editMode ? 
            <textarea
              defaultValue={defaultValue}
              placeholder={placeholder}
              onChange={handleChange}
              style={{
                width: '100%',
                minHeight: '100px',
                padding: '8px',
                paddingRight: '44px',
                border: 'none',
                resize: 'vertical',
                boxSizing: 'border-box'
              }}
              readOnly={readOnly}
            />
            :
            <div                                            
              style={{
                width: '100%',
                minHeight: '100px',
                padding: '8px',
                paddingRight: '44px',
                boxSizing: 'border-box',
                whiteSpace: 'pre-wrap',
                textAlign: 'left'
              }}
            >
              {value || <span style={{ 
                color: '#9ca3af', 
                userSelect: 'none'
              }}>{placeholder}</span>}
            </div>
          }
        </div>
        :
        
        <select
          defaultValue={defaultValue}
          placeholder={placeholder}
          onChange={handleChange}
          className={className}
          style={style}
          readOnly={readOnly}
        >
          {options.map((option, index) => (
            <option key={index} value={option.attributeValue}>
              {option.name}
            </option>
          ))}
        </select>
      }
      </>
    )
  }

  if (type === 'image') {
    return (
      <ImageUploaderSingle
        bucket="language_game_words"
        table={table}
        attribute={attribute}
        rowId={rowId}
        defaultValue={defaultValue}
        otherUpdateValues={otherUpdateValues}
        className={className}          
        readOnly={readOnly}                  
        setDbStatusCallback={(status) => {
          if (status === "Saved" && updateCallback) {
            updateCallback({
              ...otherUpdateValues,
              [attribute]: defaultValue
            });
          }
        }}
        client={supabaseClient}
        style={style}
      />
    )
  }

  return (
    <>
    {hasViewMode ?
      <div
        className={className}          
        style={{
          width: '100%',
          display: "inline-block",  
          borderRadius: "10px",
          border: "1px solid #ddd",
          overflow: "hidden",
          marginBottom: "15px",
          ...style,        
        }}
        title={"Click the edit icon to edit"}
      >
        {editMode ?                    
          <input
            defaultValue={defaultValue}
            placeholder={placeholder}
            onChange={handleChange}
            readOnly={readOnly}
            style={{
              width: 'calc(100% - 40px)',
              display: "inline-block",
              marginBottom: "0px",

            }}
          /> 
          :
          <div                                            
            style={{
              width: 'calc(100% - 40px)',
              display: "inline-block",
              textAlign: "left",
              paddingTop: "8px",
              paddingLeft: "8px",
              boxSizing: "border-box",
            }}
          >
            {value || <span style={{ 
              color: '#9ca3af', 
              userSelect: 'none',
              textAlign: 'left'
            }}>{placeholder}</span>}
          </div>       
        }
        <div
          onClick={()=>setEditMode(!editMode)}
          style={{
            width: '36px',
            height: '36px',
            lineHeight: '36px',
            display: "inline-block",
            verticalAlign: "top",
            userSelect: "none",
            cursor: "pointer",
          }}
          title={editMode ? "Done" : "Edit"}
        >
          {editMode ?  "✔" : "✏️" }

        </div>
      </div>
      :
      <input
        defaultValue={defaultValue}
        placeholder={placeholder}
        onChange={handleChange}
        className={className}
        style={style}
        readOnly={readOnly}
      />
    }
    </>
  )
}
