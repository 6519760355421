"use client"

import React from 'react';
import './ConfirmationBox.css';

export default function ConfirmationBox({ 
    message, 
    onConfirm, 
    onCancel, 
    confirmText = "Confirm",
    cancelText = "Cancel" 
}) {
    return (
        <div className="confirmation-box-overlay">
            <div className="confirmation-box">
                <div className="confirmation-message">
                    {message}
                </div>
                <div className="confirmation-buttons">
                    <button 
                        className="confirmation-button buttonInverted" 
                        onClick={onCancel}
                    >
                        {cancelText}
                    </button>
                    <button 
                        className="confirmation-button buttonInverted" 
                        onClick={onConfirm}
                    >
                        {confirmText}
                    </button>
                </div>
            </div>
        </div>
    );
}
