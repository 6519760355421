// The top 500 spanish words
// The top 500 spanish words
export const spanishWords = [
    "hola",         // hello
    "adios",        // goodbye
    "buenos dias",  // good morning
    "gracias",      // thank you
    "por favor",    // please
    "si",           // yes
    "no",           // no
    "amigo",        // friend
    "familia",      // family
    "casa",         // house
    "comida",       // food
    "agua",         // water
    "tiempo",       // time/weather
    "dia",          // day
    "noche",        // night
    "hombre",       // man
    "mujer",        // woman
    "niño",         // child/boy
    "niña",         // girl
    "trabajo",      // work
    "escuela",      // school
    "libro",        // book
    "calle",        // street
    "ciudad",       // city
    "pais",         // country
    "mundo",        // world
    "vida",         // life
    "amor",         // love
    "feliz",        // happy
    "triste",       // sad
    "grande",       // big
    "pequeño",      // small
    "nuevo",        // new
    "viejo",        // old
    "bueno",        // good
    "malo",         // bad
    "hoy",          // today
    "mañana",       // tomorrow
    "ayer",         // yesterday
    "ahora",        // now
    "despues",      // after
    "antes",        // before
    "siempre",      // always
    "nunca",        // never
    "otro",         // another
    "poco",         // little/few
    "mucho",        // much/many
    "todo",         // all/everything
    "nada",         // nothing
    "algo",         // something
    "aquí",         // here
    "allí",         // there
    "quien",        // who
    "que",          // what
    "donde",        // where
    "cuando",       // when
    "porque",       // because
    "como",         // how
    "muy",          // very
    "bien",         // well
    "también",      // also
    "pero",         // but
    "con",          // with
    "sin",          // without
    "en",           // in/on
    "a",            // to/at
    "de",           // of/from
    "y",            // and
    "o",            // or
    "uno",          // one
    "dos",          // two
    "tres",         // three
    "cuatro",       // four
    "cinco",        // five
    "seis",         // six
    "siete",        // seven
    "ocho",         // eight
    "nueve",        // nine
    "diez",         // ten
    "sol",          // sun
    "luna",         // moon
    "estrella",     // star
    "cielo",        // sky
    "mar",          // sea
    "playa",        // beach
    "montaña",      // mountain
    "río",          // river
    "árbol",        // tree
    "flor",         // flower
    "animal",       // animal
    "perro",        // dog
    "gato",         // cat
    "pájaro",       // bird
    "pez",          // fish
    "color",        // color
    "rojo",         // red
    "azul",         // blue
    "verde",        // green
    "amarillo",     // yellow
    "blanco",       // white
    "negro",        // black
    "gris",         // gray
    "naranja",      // orange
    "rosa",         // pink
    "morado",       // purple
    "café",         // brown
    "claro",        // light
    "oscuro",       // dark
    "frío",         // cold
    "caliente",     // hot
    "fácil",        // easy
    "difícil",      // difficult
    "rápido",       // fast
    "lento",        // slow
    "alto",         // tall/high
    "bajo",         // short/low
    "joven",        // young
    "viejo",        // old
    "feliz",        // happy
    "triste",       // sad
    "enojado",      // angry
    "cansado",      // tired
    "sorprendido",  // surprised
    "asustado",     // scared
    "aburrido",     // bored
    "emocionado",   // excited
    "interesante",  // interesting
    "aburrido",     // boring
    "importante",   // important
    "necesario",    // necessary
    "posible",      // possible
    "imposible",    // impossible
    "verdad",       // truth
    "mentira",      // lie
    "pregunta",     // question
    "respuesta",    // answer
    "problema",     // problem
    "solución",     // solution
    "idea",         // idea
    "pensamiento",  // thought
    "palabra",      // word
    "frase",        // phrase
    "historia",     // story/history
    "futuro",       // future
    "pasado",       // past
    "presente",     // present
    "momento",      // moment
    "vez",          // time (as in occasion)
    "parte",        // part
    "todo",         // everything
    "nada",         // nothing
    "alguien",      // someone
    "nadie",        // nobody
    "algo",         // something
    "nada",         // nothing
    "cada",         // each
    "varios",       // several
    "muchos",       // many
    "pocos",        // few
    "algunos",      // some
    "ninguno",      // none
    "otro",         // another
    "mismo",        // same
    "diferente",    // different
    "nuevo",        // new
    "viejo",        // old
    "bueno",        // good
    "malo",         // bad
    "mejor",        // better
    "peor",         // worse
    "grande",       // big
    "pequeño",      // small
    "alto",         // tall
    "bajo",         // short
    "largo",        // long
    "corto",        // short
    "ancho",        // wide
    "estrecho",     // narrow
    "pesado",       // heavy
    "ligero",       // light
    "duro",         // hard
    "blando",       // soft
    "rápido",       // fast
    "lento",        // slow
    "temprano",     // early
    "tarde",        // late
    "cerca",        // near
    "lejos",        // far
    "derecha",      // right
    "izquierda",    // left
    "arriba",       // up
    "abajo",        // down
    "dentro",       // inside
    "fuera",        // outside
    "frente",       // front
    "atrás",        // back
    "encima",       // on top
    "debajo",       // under
    "alrededor",    // around
    "entre",        // between
    "sobre",        // over/about
    "bajo",         // under
    "contra",       // against
    "sin",          // without
    "con",          // with
    "y",            // and
    "o",            // or
    "pero",         // but
    "porque",       // because
    "si",           // if
    "cuando",       // when
    "mientras",     // while
    "aunque",       // although
    "como",         // like/as
    "que",          // that/which
    "quien",        // who
    "cuyo",         // whose
    "cual",         // which
    "cuanto",       // how much
    "donde",        // where
    "por qué",      // why
    "cómo",         // how
    "cuál",         // which
    "cuánto",       // how much
    "cuándo",       // when
    "qué",          // what
    "quién",        // who
    "cuántos",      // how many
    "cuántas",      // how many
    "cuánto",       // how much
    "cuánta",       // how much
    "cuántos",      // how many
    "cuántas",      // how many
    "cuánto",       // how much
    "cuánta",       // how much
    "cuántos",      // how many
    "cuántas",      // how many
    "cuánto",       // how much
    "cuánta",       // how much
    "cuántos",      // how many
    "cuántas",      // how many
    "cuánto",       // how much
    "cuánta",       // how much
    "cuántos",      // how many
    "cuántas",      // how many
    "cuánto",       // how much
    "cuánta",       // how much
    "cuántos",      // how many
    "cuántas",      // how many
    "cuánto",       // how much
    "cuánta",       // how much
    "cuántos",      // how many
    "cuántas",      // how many
    "cuánto",       // how much
    "cuánta",       // how much
    "cuántos",      // how many
    "cuántas",      // how many
    "cuánto",       // how much
    "cuánta",       // how much
    "cuántos",      // how many
    "cuántas",      // how many
    "cuánto",       // how much
    "cuánta",       // how much
]

// top 50 spanish words
// Top 50 Spanish words
export const spanishWordObjects = [
    {
        es: "hola",
        en: "hello",
        note: "Similar to 'holla' (slang for hello)."
    },
    {
        es: "adios",
        en: "goodbye",
        note: "Think of 'adieu' in French, which also means goodbye."
    },
    {
        es: "gracias",
        en: "thank you",
        note: "Similar to 'grace'—being thankful is graceful."
    },
    {
        es: "por-favor",
        en: "please",
        note: "Literally 'for favor,' which is easy to remember."
    },
    {
        es: "si",
        en: "yes",
        note: "Same as 'si' in Italian and French."
    },
    {
        es: "no",
        en: "no",
        note: "Same as English 'no.'"
    },
    {
        es: "amigo",
        en: "friend",
        note: "Think of 'amiable' in English, which means friendly."
    },
    {
        es: "familia",
        en: "family",
        note: "Same root as 'familiar'—people you know well."
    },
    {
        es: "casa",
        en: "house",
        note: "Think of 'casita' (little house)."
    },
    {
        es: "comida",
        en: "food",
        note: "Similar to 'comestible' (edible)."
    },
    {
        es: "agua",
        en: "water",
        note: "Think of 'aqua' in Latin, which means water."
    },
    {
        es: "tiempo",
        en: "time/weather",
        note: "Think of 'tempo' in music, which relates to time."
    },
    {
        es: "dia",
        en: "day",
        note: "Think of 'diary,' which is a daily record."
    },
    {
        es: "noche",
        en: "night",
        note: "Think of 'nocturnal,' which relates to night."
    },
    {
        es: "hombre",
        en: "man",
        note: "Think of 'homo' (Latin for human)."
    },
    {
        es: "mujer",
        en: "woman",
        note: "Think of 'madam' or 'ma'am,' which refer to women."
    },
    {
        es: "niño",
        en: "child/boy",
        note: "Think of 'niña' for girl, and 'niño' for boy."
    },
    {
        es: "niña",
        en: "girl",
        note: "Think of 'niño' for boy, and 'niña' for girl."
    },
    {
        es: "trabajo",
        en: "work",
        note: "Think of 'labor,' which also means work."
    },
    {
        es: "escuela",
        en: "school",
        note: "Think of 'scholar,' which relates to school."
    },
    {
        es: "libro",
        en: "book",
        note: "Think of 'library,' where books are kept."
    },
    {
        es: "calle",
        en: "street",
        note: "Think of 'alley,' which is similar to a street."
    },
    {
        es: "ciudad",
        en: "city",
        note: "Think of 'citizen,' which relates to a city."
    },
    {
        es: "pais",
        en: "country",
        note: "Think of 'paisano,' which means fellow countryman."
    },
    {
        es: "mundo",
        en: "world",
        note: "Think of 'mundane,' which relates to the world."
    },
    {
        es: "vida",
        en: "life",
        note: "Think of 'vital,' which relates to life."
    },
    {
        es: "amor",
        en: "love",
        note: "Think of 'amorous,' which relates to love."
    },
    {
        es: "feliz",
        en: "happy",
        note: "Think of 'felicity,' which means happiness."
    },
    {
        es: "triste",
        en: "sad",
        note: "Think of 'tristesse' in French, which means sadness."
    },
    {
        es: "grande",
        en: "big",
        note: "Think of 'grand,' which means large or impressive."
    },
    {
        es: "pequeño",
        en: "small",
        note: "Think of 'petite,' which means small."
    },
    {
        es: "nuevo",
        en: "new",
        note: "Think of 'novel,' which means new or original."
    },
    {
        es: "viejo",
        en: "old",
        note: "Think of 'veteran,' which relates to being old or experienced."
    },
    {
        es: "bueno",
        en: "good",
        note: "Think of 'benign,' which means good or kind."
    },
    {
        es: "malo",
        en: "bad",
        note: "Think of 'malice,' which means bad intent."
    },
    {
        es: "hoy",
        en: "today",
        note: "Think of 'hoyden,' which means a lively person (today is lively!)."
    },
    {
        es: "mañana",
        en: "tomorrow",
        note: "Think of 'mañana' as 'morning,' which is the start of a new day."
    },
    {
        es: "ayer",
        en: "yesterday",
        note: "Think of 'eve,' which is the day before."
    },
    {
        es: "ahora",
        en: "now",
        note: "Think of 'hour,' which relates to the present time."
    },
    {
        es: "despues",
        en: "after",
        note: "Think of 'post,' which means after."
    },
    {
        es: "antes",
        en: "before",
        note: "Think of 'ante,' which means before."
    },
    {
        es: "siempre",
        en: "always",
        note: "Think of 'sempiternal,' which means eternal or always."
    },
    {
        es: "nunca",
        en: "never",
        note: "Think of 'null,' which means nothing or never."
    },
    {
        es: "otro",
        en: "another",
        note: "Think of 'other,' which is similar to another."
    },
    {
        es: "poco",
        en: "little/few",
        note: "Think of 'poco' as 'a little bit.'"
    },
    {
        es: "mucho",
        en: "much/many",
        note: "Think of 'much' in English."
    },
    {
        es: "todo",
        en: "all/everything",
        note: "Think of 'total,' which means all."
    },
    {
        es: "nada",
        en: "nothing",
        note: "Think of 'nada' as 'not a thing.'"
    },
    {
        es: "algo",
        en: "something",
        note: "Think of 'algún,' which means some."
    },
];