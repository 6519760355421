import React, { useEffect, useState } from 'react'

export default function SettingsWindow2({
    loadSettingsCallback = () => {}, 
    defaultSettings = {
        masWordArrayLength: 4,
    }, 
    close
}) {

    const [userMessage, setUserMessage] = useState()
    const [settings, setSettings] = useState(defaultSettings)

    useEffect(()=>{
        loadSettings()
    },[])

    function loadSettings(){
        // load from loacal storage
        let loadedSettings = window.localStorage.getItem("language_game_settings")
        
        // Save the loaded settings in state (if there are loaded settings)
    
        if(loadedSettings && typeof loadedSettings === "string"){
            setSettings(JSON.parse(loadedSettings))        
        }
        // If there are no settings 
        else{            
            // Put the default settings in localStorage
            window.localStorage.setItem("language_game_settings", JSON.stringify(defaultSettings))
            // Set the local state for settings to the default settings to match the 
            setSettings(defaultSettings)
            // After the default settings are saved this will cause the parent comonent to reload the new settings
            updateInParent()            
        }
        
    }

    // Called when an input changes and updates the settings json object in localStorage
    function updateSetting(attribute, newValue, type){
        let newValueTemp = newValue
        
        // If it is supposed to be an integer try to convert it
        if(type==="integer"){
            try{
                newValueTemp = Number.parseInt(newValue)
            }catch{
                return
            }
        }
        
        
        // Update the localStorage object
        let tempSettings = {...settings}
        tempSettings[attribute] = newValueTemp
        window.localStorage.setItem("language_game_settings", JSON.stringify(tempSettings))

        // Cause the settings to be reloaded in the parent so they are up to date
        updateInParent()
    }

    // Calls a callback functin after a timer so the settings are reloaded in the parent component
    function updateInParent(){
        // Call the callback function so it reloads (after a short delay to avoid errors)
        setTimeout(() => {
            loadSettingsCallback()            
        }, 250);
    }

    return (
        <div className='window settingsWindow'>
            <div className='closeButton' onClick={close}>x</div>
            <div>
                <div>Max Word Array Length</div>
                <input 
                    onChange={(e)=>updateSetting("maxWordArrayLength", e.target.value, "integer")} 
                    defaultValue={settings?.maxWordArrayLength}
                ></input>
            </div>
            
        </div>
    )
}