import React, { useEffect, useState } from 'react'
import { BarChart, CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'

export function dateStringUTC(){
  const currentdate = new Date()

  return currentdate.getFullYear() + "-"
  // The month is an index 0 - 11
  + (currentdate.getUTCMonth()).toString().padStart(2, '0') + "-"  
  + currentdate.getUTCDate().toString().padStart(2, '0') + "T"
  + currentdate.getUTCHours().toString().padStart(2, '0') + ":"  
  + currentdate.getUTCMinutes().toString().padStart(2, '0') + ":" 
  + currentdate.getUTCSeconds().toString().padStart(2, '0');
}
export function dateString(){
  const currentdate = new Date()

  return currentdate.getFullYear() + "-"
  // The month is an index 0 - 11
  + (currentdate.getUTCMonth()).toString().padStart(2, '0') + "-"  
  + currentdate.getUTCDate().toString().padStart(2, '0') + "T"
  + currentdate.getUTCHours().toString().padStart(2, '0') + ":"  
  + currentdate.getUTCMinutes().toString().padStart(2, '0') + ":" 
  + currentdate.getUTCSeconds().toString().padStart(2, '0');
}
function Charts({name, close, logObject}) {

    const [dailyValues, setDailyValues] = useState([])
    const [dayEvaluation, setDayEvaluation] = useState({})

    useEffect(()=>{
      // createPointsPerDay()
      let tempArray = filterAndFillDates(aggregateLogEntries(logObject)) 
      setDailyValues(tempArray)
      setDayEvaluation(compareTodayToAverage(tempArray))
    },[logObject])


    function createPointsPerDay(){
      if(typeof logObject !== "object") return

      let dailyValuesObject = {}
      Object.entries(logObject).forEach(logObjectEntry => {
        // The date without the time
        let date = logObjectEntry[0].split("T")[0]
  
        // If there is not an object for this date already add an empty object there
        if(!dailyValuesObject[date]){
          dailyValuesObject[date] = {}          
        }

        // The key values pairs in the log entry
        let logValues = logObjectEntry[1] 
        // Look at each key value pair in the log data object entry
        Object.entries(logValues).forEach(pair => {
          let key = pair[0]
          let value = pair[1]

          // For the array length save the largest value
          if(key === "arrayLength"){
            // If there is a value for arrayLength
            if(dailyValuesObject[date]?.arrayLength){
              // And it is larger than the stored value
              if(value > dailyValuesObject[date]?.arrayLength )
                // Save it
                dailyValuesObject[date].arrayLength = value
            }
            // If there is no stored value save it
            else{
              dailyValuesObject[date].arrayLength = value
            }
          }
          // For the other two (points and seconds) add the values to the existing stored values
          else{
            // If there is a value for that key add the new amount into it
            if(dailyValuesObject[date][key])
              dailyValuesObject[date][key] += value          
            // Else set the initial value
            else
              dailyValuesObject[date][key] = value        
          }

        })

        // Calculate the points per second
        if(dailyValuesObject[date]?.points && dailyValuesObject[date]?.seconds){
          dailyValuesObject[date].pointsPerSecond = dailyValuesObject[date].points / dailyValuesObject[date].seconds
        }

      })

      // Convert that object into an array so the chart can display it
      let tempDailyValuesObjectArray = []
      let earliestDate = new Date()
      let latestDate = new Date()
      Object.entries(dailyValuesObject).forEach(dailyEntry => {
        let tempObject = {}
        let date = dailyEntry[0]
        let values = dailyEntry[1]
        
        let dateOfThisEntry = new Date(date)
        // Have to add 1 because its an index by default
        dateOfThisEntry.setDate(dateOfThisEntry.getDate() + 1)

        if(dateOfThisEntry < earliestDate)
          earliestDate = dateOfThisEntry
        if(dateOfThisEntry >= latestDate)
          latestDate = dateOfThisEntry

        tempObject.date = date
        // Add each key value pair (this is better than hard coding because any new pairs will be added automatically)
        Object.entries(values).forEach(kvPair => {          
          let key = kvPair[0]
          let value = kvPair[1]
          tempObject[key] = value
        })
        // Put the object into the array
        tempDailyValuesObjectArray.push(tempObject)
      })      

      // Create an object that has all the dates between the earliest and latest day
      let allDatesArray = []
      let dateCounter = new Date(earliestDate.getTime())

      while(dateCounter <= latestDate){
        // If this date is in the dailyValuesObject add it with those values, else add it with 0s
        if(dailyValuesObject[dateString(dateCounter, true)])
          allDatesArray.push({...dailyValuesObject[dateString(dateCounter, true)], date: dateString(dateCounter, true)})
        else
            allDatesArray.push({
              date: dateString(dateCounter, true),
              points: 0,
              seconds: 1,
              arrayLength: 0,
              pointsPerSecond: 0,
            })

        // Increment the date counter by one day
        dateCounter.setDate(dateCounter.getDate() + 1)
      }

      // Setting this to an array with all of the dates and the values to graph
      setDailyValues(allDatesArray)
      
    }

    function aggregateLogEntries(logEntries) {
      // Initialize an object to store aggregated data by date
      const aggregatedData = {};
    
      // Iterate over each log entry
      for (const dateTime in logEntries) {
        if (logEntries.hasOwnProperty(dateTime)) {
          // Extract the date (YYYY-MM-DD) from the dateTime string
          const date = dateTime.split('T')[0];
    
          // Initialize the date entry in aggregatedData if it doesn't exist
          if (!aggregatedData[date]) {
            aggregatedData[date] = {
              points: 0,
              seconds: 0,
              arrayLength: 0,
            };
          }
    
          // Aggregate the data for the current date
          aggregatedData[date].points += logEntries[dateTime].points;
          aggregatedData[date].seconds += logEntries[dateTime].seconds;
          aggregatedData[date].arrayLength = Math.max(aggregatedData[date].arrayLength, logEntries[dateTime].arrayLength);
        }
      }
    
      // Convert the aggregated data into the desired array format
      const result = [];
      for (const date in aggregatedData) {
        if (aggregatedData.hasOwnProperty(date)) {
          const { points, seconds, arrayLength } = aggregatedData[date];
          const pointsPerSecond = points / seconds;
    
          result.push({
            date: date,
            points: points,
            seconds: seconds,
            arrayLength: arrayLength,
            pointsPerSecond: pointsPerSecond,
          });
        }
      }
    
      return result;
    }

    function filterAndFillDates(aggregatedArray, numDays = 7) {
      // Get the current date
      const currentDate = new Date();
    
      // Initialize a set to keep track of existing dates in the aggregated array
      const existingDates = new Set(aggregatedArray.map(entry => entry.date));
    
      // Initialize the result array
      const result = [];
    
      // Iterate over the past `numDays` days
      for (let i = numDays - 1; i >= 0; i--) {
        // Calculate the date for `i` days ago
        const pastDate = new Date();
        pastDate.setDate(currentDate.getDate() - i);
        const dateString = pastDate.toISOString().split('T')[0];
    
        // Check if the date exists in the existing dates set
        if (existingDates.has(dateString)) {
          // Find the existing entry and add it to the result array
          const existingEntry = aggregatedArray.find(entry => entry.date === dateString);
          result.push(existingEntry);
        } else {
          // Add a new entry with zero values
          result.push({
            date: dateString,
            points: 0,
            seconds: 0,
            arrayLength: 0,
            pointsPerSecond: 0
          });
        }
      }
    
      return result;
    }
    
    function compareTodayToAverage(aggregatedArray) {
      // Get the current date in YYYY-MM-DD format
      const currentDate = new Date().toISOString().split('T')[0];
    
      // Find today's entry in the aggregated array
      const todayEntry = aggregatedArray.find(entry => entry.date === currentDate);
    
      // If there is no score for today, return "non existent"
      if (!todayEntry) {
        return { wordValue: "Non-existent", percentage: "0%" };
      }
    
      // Get the previous 3 days' entries
      const previous3Days = aggregatedArray.slice(-4, -1);
    
      // If there are less than 3 previous days, return "non existent"
      if (previous3Days.length < 3) {
        return { wordValue: "Non-Existent", percentage: "0%" };
      }
    
      // Calculate the average points of the previous 3 days
      const totalPointsPrevious3Days = previous3Days.reduce((sum, entry) => sum + entry.points, 0);
      const averagePointsPrevious3Days = totalPointsPrevious3Days / 3;
    
      // Calculate the percentage change
      const percentageChange = ((todayEntry.points - averagePointsPrevious3Days) / averagePointsPrevious3Days) * 100;
    
      // Determine the word value based on the percentage change
      let wordValue;
      if (percentageChange < 0) {
        wordValue = "Danger";
      } else if (percentageChange >= 0 && percentageChange <= 10) {
        wordValue = "Sustaining";
      } else if (percentageChange > 10) {
        wordValue = "Affluence";
      }
    
      return { wordValue: wordValue, percentage: percentageChange.toFixed(2) + "%", previous3Total: totalPointsPrevious3Days};
    }
    
    

  return (
    <>
    <div className='window chartWindow'>
        <div className='closeButton' onClick={close}>x</div>
        <h2>{name}</h2>
        <div >
          <h3> {"Todays Evaluation: "+dayEvaluation?.wordValue}</h3>          
          <h5> {dayEvaluation.percentage + " chage from previous 3 avg of "+dayEvaluation.previous3Total}</h5>          
        </div>
        <div className='chartContainer'>
        <h3>{"Points per Day"}</h3>
          <LineChart
              width={800}
              height={400}
              data={dailyValues}
              margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
              >
              <XAxis dataKey="date" />
              <YAxis></YAxis>
              <Tooltip />
              <CartesianGrid stroke="#f5f5f5" />
              <Line type="monotone" dataKey="points" stroke="#ff7300" yAxisId={0} />
              {/* <Line type="monotone" dataKey="pv" stroke="#387908" yAxisId={1} /> */}
          </LineChart>
        </div>
        <div className='chartContainer'>
          <h3>{"Points Per Second Per Day"}</h3>
          <LineChart
              width={800}
              height={400}
              data={dailyValues}
              margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
              >
              <XAxis dataKey="date" />
              <YAxis></YAxis>
              <Tooltip />
              <CartesianGrid stroke="#f5f5f5" />
              <Line type="monotone" dataKey="pointsPerSecond" stroke="#ff7300" yAxisId={0} />
              {/* <Line type="monotone" dataKey="pv" stroke="#387908" yAxisId={1} /> */}
          </LineChart>
        </div>
        <div className='chartContainer'>
          <h3>{"Max Array Length"}</h3>
          <LineChart
              width={800}
              height={400}
              data={dailyValues}
              margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
              >
              <XAxis dataKey="date" />
              <YAxis></YAxis>
              <Tooltip />
              <CartesianGrid stroke="#f5f5f5" />
              <Line type="monotone" dataKey="arrayLength" stroke="#ff7300" yAxisId={0} />
              {/* <Line type="monotone" dataKey="pv" stroke="#387908" yAxisId={1} /> */}
          </LineChart>
        </div>
    </div>
    </>
  )
}

export default Charts