import React, { useEffect, useState } from 'react'
import "./StudentPortal.css"
import { scrollTop } from '../../Global/Functions'
import { auth } from '../../Global/firebaseInit'
import AuthPage from './Auth/AuthPage'
import { supabaseClient } from '../../Global/supabaseinit'
import StudentPortalHome from './Portal/StudentPortalHome'
function StudentPortal() {

    const [user, setUser] = useState()

    // Maybe not necessary
    useEffect(()=>{
        scrollTop()
    },[])

    useEffect(()=>{
        listenForAuthChanges()
        
    },[])

    async function listenForAuthChanges() {
        const { data: { subscription } } = await supabaseClient.auth.onAuthStateChange((event, session) => {
            console.log("auth state changed:", event, session)
            setUser(session?.user)
        })
        return () => {
            subscription?.unsubscribe()
        }
    }
    

  return (
    <div className='page studentPortalPage'>            
        {user ? 
            <StudentPortalHome></StudentPortalHome>
            :
            <AuthPage></AuthPage>
        }        
    </div>
  )
}

export default StudentPortal