import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import "./Program.css"
import { programBannerURL, scrollTop } from '../../Global/Functions'
import percent95 from "../../Images/percent95_3.png"
import percent97 from "../../Images/percent97_3.png"
import percent99 from "../../Images/percent99_3.png"
import {businessBAProgramTiles, businessCEProgramTiles, businessMAProgramTiles, educationBAProgramTiles, educationMAProgramTiles, healthcareBAProgramTiles, healthcareCEProgramTiles, healthcareMAProgramTiles, itBAProgramTiles, itCEProgramTiles, itMAProgramTiles} from '../../DataProcessing/CourseTiles'
import BusinessFAQ from '../../Components/FAQ/BusinessFAQ'
import ITFAQ from '../../Components/FAQ/ITFAQ'
import EducationFAQ from '../../Components/FAQ/EducationFAQ'
import HealthcareFAQ from '../../Components/FAQ/HealthcareFAQ'
// import { businessBAProgramTiles } from '../../DataProcessing/CourseTiles'

function Program() {
    const {program, degreeType} = useParams()
        
    // ================================================================================
    // #region Initial Values: programTiles, bannerTitle, faqContent, loadBanner, scrollTop 

    const [programTiles, setProgramTiles] = useState([])
    useEffect(()=>{
        setProgramTilesFunction()
        setBannerTitleFunction()
        scrollTop()
        loadBanner()
    },[])
    
    function setProgramTilesFunction(){
        // setProgramTiles(filterProgramsFunctoin(combinedProgramData, program, degreeType, ))
        // return
        // setProgramTiles(filterPrograms2(consolidatedPrograms2, degreeType, program))
        // return
        // Business
        if(program === "business"){
            // BA
            if(degreeType === "bachelors"){
                setProgramTiles(businessBAProgramTiles)
            }
            // MA
            else if(degreeType === "masters"){
                setProgramTiles(businessMAProgramTiles)

            }
            // Certs
            else if(degreeType === "certificates"){
                setProgramTiles(businessCEProgramTiles)

            }
            // No Degree Type Specified
            else{
                setProgramTiles([...businessBAProgramTiles, ...businessMAProgramTiles, ...businessCEProgramTiles])

            }
        }
        // IT
        else if(program === "it"){
            // BA
            if(degreeType === "bachelors"){
                setProgramTiles(itBAProgramTiles)
            }
            // MA
            else if(degreeType === "masters"){
                setProgramTiles(itMAProgramTiles)

            }
            // Certs
            else if(degreeType === "certificates"){
                setProgramTiles(itCEProgramTiles)
            }
            // No Degree Type Specified
            else{
                setProgramTiles([...itBAProgramTiles, ...itMAProgramTiles, ...itCEProgramTiles])
            }
        }
        // Healthcare
        else if(program === "healthcare"){
            // BA
            if(degreeType === "bachelors"){
                setProgramTiles(healthcareBAProgramTiles)

            }
            // Masters
            if(degreeType === "masters"){
                setProgramTiles(healthcareMAProgramTiles)

            }
            // Certs
            else if(degreeType === "certificates"){
                setProgramTiles(healthcareCEProgramTiles)

            }
            // No Degree Type Specified
            else{
                setProgramTiles([...healthcareBAProgramTiles, ...healthcareMAProgramTiles, ...healthcareCEProgramTiles])

            }
        }
        // Education
        else if(program === "education"){
            // BA
            if(degreeType === "bachelors"){
                setProgramTiles(educationBAProgramTiles)

            }
            // Masters
            if(degreeType === "masters"){
                setProgramTiles(educationMAProgramTiles)

            }
            // Certs
            else if(degreeType === "certificates"){
                // setProgramTiles(educationCEProgramTiles)

            }
            // No Degree Type Specified
            else{
                setProgramTiles([...educationBAProgramTiles, ...educationMAProgramTiles])

            }
        }

        // No Program Specified
        else{
            setProgramTiles([...itBAProgramTiles, ...businessBAProgramTiles, ...healthcareBAProgramTiles, ...educationBAProgramTiles, ...itMAProgramTiles, ...businessMAProgramTiles, ...healthcareMAProgramTiles, ...educationMAProgramTiles, ...itCEProgramTiles, ...businessCEProgramTiles, ...healthcareCEProgramTiles])

        }

    }
    const [bannerTitle, setBannerTitle] = useState("The Bachelor's Degree in Business Designed to Help You Stand Out")
    function setBannerTitleFunction(){
        // Business
        if(program === "business"){
            // BA
            if(degreeType === "bachelors"){
                setBannerTitle("The Bachelors's Degrees in Business Administration Designed to Help You Stand Out")
                
            }
            // MA
            else if(degreeType === "masters"){
                setBannerTitle("The Masters's Degrees in Business Administration Designed to Help You Stand Out")
                
            }
            // Certs
            else if(degreeType === "certificates"){
                setBannerTitle("The Business Administration Certificates Designed to Help You Stand Out")
                
            }
            // No Type Specified
            else{
                setBannerTitle("The Business Administration Programs Designed to Help You Stand Out")
            }
        }
        // IT
        else if(program === "it"){
            // BA
            if(degreeType === "bachelors"){
                setBannerTitle("The Bachelor's Degrees in Information Technology Designed to Help You Stand Out")

            }
            // MA
            else if(degreeType === "masters"){
                setBannerTitle("The Masters's Degrees in Information Technology Designed to Help You Stand Out")

            }
            // Certs
            else if(degreeType === "certificates"){
                setBannerTitle("The Information Technology Certificates Designed to Help You Stand Out")

            }
            // BA
            else{
                setBannerTitle("The Information Technology Programs Designed to Help You Stand Out")
            }
        }
        // Education
        else if(program === "education"){
            // BA
            if(degreeType === "bachelors"){
                setBannerTitle("The Bachelor's Degrees in Education Designed to Help You Stand Out")

            }
            // Masters
            else if(degreeType === "masters"){
                setBannerTitle("The Masters's Degrees in Education Designed to Help You Stand Out")

            }
            // Certs
            else if(degreeType === "certificates"){
                setBannerTitle("The Education Certificates Designed to Help You Stand Out")

            }
            // No Type Specified
            else{
                setBannerTitle("The Education Programs Designed to Help You Stand Out")
            }
        }
        else if(program === "healthcare"){
            // BA
            if(degreeType === "bachelors"){
                setBannerTitle("The Bachelor's Degrees in Healthcare Designed to Help You Stand Out")

            }
            // Masters
            else if(degreeType === "masters"){
                setBannerTitle("The Masters's Degrees in Healthcare Designed to Help You Stand Out")

            }
            // Certs
            else if(degreeType === "certificates"){
                setBannerTitle("The Healthcare Certificates Designed to Help You Stand Out")

            }
            // No Type Specified
            else{
                setBannerTitle("The Healthcare Programs Designed to Help You Stand Out")
            }
        }
        // No Program Specified
        else{
            setBannerTitle("Degrees & Certificates Designed to Help You Stand Out")
        }
    }
    function faqContent(){
        // Business Q & A
        if(program === "business")
            return(<>
                <BusinessFAQ></BusinessFAQ>
            </>)
        // Information Technology Q & A
        else if (program === "it")
            return(<>
                <ITFAQ></ITFAQ>
            </>)
        // Education Q & A
        else if (program === "education")
            return(<>
                <EducationFAQ></EducationFAQ>
            </>)
        // Healthcare Q & A
        else if (program === "healthcare")
            return(<>
                <HealthcareFAQ></HealthcareFAQ>
            </>)
        // If not program is specified show all
        else
            return(<>
                <BusinessFAQ></BusinessFAQ>
                <ITFAQ></ITFAQ>
                <HealthcareFAQ></HealthcareFAQ>
                <EducationFAQ></EducationFAQ>

                {/* Business */}

                {/* <FAQLine
                    title="Is a business degree worth it?"
                    content={`
Yes! A degree in business can help you advance in your career, improve your prospects for a new job, and likely increase your earning potential. For your career, a bachelor's degree in business is a great investment.
                    `}
                ></FAQLine>
                <FAQLine
                    title="What kinds of jobs can you get with a degree in business?"
                    content={`
There are many different degrees you can get in business. While jobs may vary, a degree in business might prepare you for jobs like the following:

    + Marketer
    + Loan officer
    + Financial advisor
    + Human resources manager
    + Entrepreneur
    + Sales manager
    + Accountant
                    `}
                ></FAQLine> */}
                
                {/* Information Technology */}
                
                {/* <FAQLine
                    title="Is a degree in information technology worth it?"
                    content={`
Yes! The global information technology market is expected to reach $5 trillion this year. The Bureau of Labor Statistics projects a 12% growth rate between 2018 and 2028, with some jobs projected to grow as much as 22% from 2020 to 2030. Information technology careers are among the highest paid for entry level employees.
                    `}
                ></FAQLine>
                <FAQLine
                    title="What jobs can you get with an IT degree?"
                    content={`
There are a wide variety of jobs you can get with an IT degree. Some of the most popular are:

    + Network engineer
    + Software or web developer
    + Information security or computer systems analyst
    + Computer programmer
    + Database or computer systems administrator
    + IT manager
    + Cybersecurity expert
                    `}
                ></FAQLine> */}

                {/* Education */}

                {/* <FAQLine
                    title="Are there open positions in the educaiton field?"
                    content={`
As long as there are kids, there will be a need for people to teach them. This means job security for professionals with an education degree. In addition, public schools often benefit from teacher unions that help keep good educators in the classroom.

According to the Bureau of Labor Statistics, elementary teachers expect to see a 4% increase in the number of jobs by 2029. The same is true for high school teachers. Those employed in post-secondary education can expect a 9% increase in demand during that time frame. Teachers are much needed, and that is a definite perk to the profession.

                    `}
                ></FAQLine>
                <FAQLine
                    title="How can a degree in education can be rewarding?"
                    content={`
You Get to Help Students:

As an educator, your job is to help students succeed, both academically as well as socially and emotionally. This is highly rewarding and can help motivate you to go to work each day. Every day is different, as well, as you work with students to get through problems that impact their learning and development.

You Make a Difference:

Helping students succeed means you are making a difference, not just in the lives of the students in your classroom, but also in society as a whole. Society is only as strong as its future leaders will be, and as an educator, you are growing and developing those future leaders. Every time you go to work, you are making a difference in the lives of the students you see, and that is highly rewarding.

Seeing Students Learn New Skills:

As you teach, you have the chance to watch students learn and grasp new skills. Many teachers describe the “light bulb” moment that happens when a student finally grasps a difficult concept. That is a highly rewarding experience, and something you get to see on an almost daily basis as an educator.

It doesn’t matter if you are teaching pre-calc to seniors or reading to kindergarten students, that chance to see the information you are presenting finally “click” makes all the hard work of an education major worthwhile.

`}
                ></FAQLine>
                <FAQLine
                    title="Are there jobs in the healthcare industry?"
                    content={`
Yes! the healthcare industry is experiencing rapid growth. According to a 2018 report by The Atlantic, “for the first time in history, healthcare has surpassed manufacturing and retail, the most significant job engines of the 20th century, to become the largest source of jobs in the U.S.”

This explosive growth is a result of both our aging population and our healthcare services becoming increasingly complex. For those with a passion for healthcare and good business acumen, a healthcare management degree is the ticket to a rewarding and secure career path in the health field.

`}
                ></FAQLine> */}
            </>)
    }
    const [bannerUrl, setBannerUrl] = useState()
    function loadBanner(){  
        setBannerUrl(programBannerURL())
    }

    // #endregion initial values

    // ================================================================================
    // #region Filter
    
    const [filteredProgramTiles, setFilteredProgramTiles] = useState([])
    useEffect(()=>{
        filterProgramTiles()
    },[programTiles])
    function filterProgramTiles(){
        let searchTerm = document.getElementById("searchInput")?.value?.toLowerCase()
        let tempFilteredTiles = []
        programTiles.forEach(programTileData => {
            if(programTileData?.name?.toLowerCase()?.includes(searchTerm))
                tempFilteredTiles.push(programTileData)
        })
        setFilteredProgramTiles(tempFilteredTiles)
    }
    
    // #endregion Filter

    return (
        <div className='innerPage'>
            
            {/* Banner */}
            
            <div className='programBanner'>
                {/* <img src={"../../"+programBannerURL()} className='programBannerImg'></img> */}
                <img src={bannerUrl} className='programBannerImg'></img>
                <div className='programBannerText'>
                    <div className='programBannerSubtitle'>DEGREES & PROGRAMS</div>
                    <div className='programBannerTitle'>
                        {bannerTitle}
                    </div>
                    <Link to={"/apply"}>
                        <button className='programBannerButton'>Apply Now</button>
                    </Link>
                </div>
            </div>

            {/* Search Bar */}

            <div className='searchBar'>
                <input className='searchBarInput' placeholder='Search Courses (ex: marketing or cybersecurity)' id='searchInput' onChange={filterProgramTiles}></input>
            </div>

            {/* Program Tiles */}

            <div className='programTiles'>
                {filteredProgramTiles.map((programTileData, index) => (
                    <div className='programTile' key={programTileData.name+"i"+index}>
                        <div className='programType'>
                            <img src={programTileData?.typImg}></img>
                            <div className='programTypeText'>
                                {programTileData.type}
                            </div>
                        </div>
                        <div className='programName'>
                            {programTileData.name}
                        </div>
                        <div className='programDescription'>
                            {programTileData.description}
                        </div>
                        <Link to={"/program-detail/" + "<program-name>"}>
                            <button className='programTilebutton'>More Details</button>
                        </Link>
                    </div>
                ))}
            </div>

            {/* Stats */}

            <div className='statsArea'>
                <div className='statsTitleArea'>
                    <div className='statsTitle'>
                        Prepared for Success on the Job
                    </div>
                    <div className='statsTitleDisclaimer'>
                        *From a 2022 poll of 200 employers of Whalley graduates.
                    </div>
                </div>
                <div className='statsBox'>
                    <img src={percent97}/>
                    <div className='statsBoxDescription'>
                        97% of employers said that Whalley graduates were prepared for their jobs.*
                    </div>
                </div>
                <div className='statsBox'>
                    <img src={percent95}/>
                    <div className='statsBoxDescription'>
                        95% of employers said that they would hire another Whalley grad.*
                    </div>
                </div>
                <div className='statsBox'>
                    <img src={percent99}/>
                    <div className='statsBoxDescription'>
                    99% of employers said Whalley graduates met or exceeded expectations.*
                    </div>
                </div>
            </div>

            {/* FAQ */}

            <div className='pageInnerWidth'>
                {faqContent()}
            </div>

        </div>
    )
}

export default Program