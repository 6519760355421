// Simple stories in spanish 
export const spanishStories = [
    {
        title: "La Manzana Roja",
        contentSections: [
            `Ana tiene hambre. Ve una manzana roja en la mesa. La manzana es grande y brillante. Ana quiere comerla, pero no puede alcanzarla. Busca una silla. Sube a la silla y toma la manzana. La manzana está dulce y jugosa. Ana sonríe porque está feliz.`
        ]
    },        
    {
        title: "El Día de Lluvia",
        contentSections: [
            `Hoy es un día de lluvia. Carlos no puede jugar afuera. Carlos está triste. Su mamá le dice: "Podemos hacer galletas". Carlos ayuda a su mamá. Mezclan harina, azúcar y mantequilla. Las galletas huelen muy bien. Carlos come una galleta caliente. Ahora está feliz, aunque llueve.`
        ]
    },        
    {
        title: "El Gato Curioso",
        contentSections: [
            `Un gato llamado Luna es muy curioso. Ve una caja en el suelo. La caja está cerrada. Luna quiere saber qué hay dentro. Empuja la caja con su pata. La caja se abre. Dentro hay un juguete pequeño. Luna juega con el juguete todo el día. Está muy contenta.`
        ]
    },    
    {
        title: "El Viaje en Bicicleta",
        contentSections: [
            `Pablo tiene una bicicleta nueva. Hoy va al parque con su bicicleta. El sol brilla y el cielo está azul. Pablo pedalea rápido. Siente el viento en su cara. En el parque, Pablo ve a sus amigos. Juegan juntos todo el día. Pablo está cansado pero feliz. Es un día perfecto.`
        ]
    },
    {
            
        title: "El Amor",
        contentSections: [
            `
                El amor es algo que no se puede explicar,
                es un sentimiento que no se puede controlar.
                El amor es algo que no se puede evitar,
                es algo que te hace volar.
            `,
            `
                El amor es como el agua que cae del cielo,
                es como el viento que mueve todo el suelo.
                El amor es como el sol que alumbra el día,
                es como la luna que ilumina la noche mía.
            `,
            `
                El amor es algo que no se puede comprar,
                es algo que te hace soñar.
                El amor es algo que no se puede olvidar,
                es algo que te hace vibrar.
            `,
            `
                El amor es como el fuego que quema en el alma,
                es como la brisa que te calma.
                El amor es como el mar que nunca se acaba,
                es como la vida que siempre te alaba.
            `,
            `
                El amor es algo que no se puede explicar,
                es un sentimiento que no se puede controlar.
                El amor es algo que no se puede evitar,
                es algo que te hace volar.
            `
        ]
    },   
    {
        title: "Gasolina",
        contentSections: [
            `
                Dame más gasolina,
                cómo le encanta la gasolina.
            `,
            `
                Ella prende las turbinas,
                no discrimina, no se resigna.
                Ella prende las turbinas,
                no discrimina, no se resigna.
            `,
            `
                A ella le gusta la gasolina,
                dame más gasolina.
                A ella le gusta la gasolina,
                dame más gasolina.
            `,
            `
                Como le encanta la gasolina,
            `
        ]
    }, 
    {
        title: "Despacito",
        contentSections: [
            `
                Sí, sabes que ya llevo un rato mirándote,
                tengo que bailar contigo hoy.
                Vi que tu mirada ya estaba llamándome,
                muéstrame el camino que yo voy.
            `,
            `
                Oh, tú, tú eres el imán y yo soy el metal,
                me voy acercando y voy armando el plan.
                Solo con pensarlo se acelera el pulso,
                ya, ya me estás gustando más de lo normal.
            `,
            `
                Despacito, quiero respirar tu cuello despacito,
                dejar que te diga cosas al oído,
                para que te acuerdes si no estás conmigo.
            `,
            `
                Pasito a pasito, suave suavecito,
                nos vamos pegando, poquito a poquito.
                Y es que esa belleza es un rompecabezas,
                pero pa' montarlo aquí tengo la pieza.
            `
        ]
    },
    {
        title: "Me Gustas Tú",
        contentSections: [
            `
                Me gustan los aviones, me gustas tú.
                Me gusta viajar, me gustas tú.
                Me gusta la mañana, me gustas tú.
                Me gusta el viento, me gustas tú.
            `,
            `
                Me gusta soñar, me gustas tú.
                Me gusta la mar, me gustas tú.
                Me gusta la moto, me gustas tú.
                Me gusta correr, me gustas tú.
            `,
            `
                Me gusta la cena, me gustas tú.
                Me gusta la vecina, me gustas tú.
                Me gusta la vida, me gustas tú.
                Me gusta la tierra, me gustas tú.
            `,
            `
                Me gusta la gente, me gustas tú.
                Me gusta la paz, me gustas tú.
                Me gusta la lluvia, me gustas tú.
                Me gusta la noche, me gustas tú.
            `
        ]
    },
]