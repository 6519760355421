import React, { useState } from 'react'
import { supabaseClient } from '../../../Global/supabaseinit'
import { Link } from 'react-router-dom'

/*
    This component loads the students user data
    
    they can view clases
    view and update personal informaiotn    

*/
export default function StudentPortalHome() {
    
    const [classes, setClasses] = useState([
        {
            id: 1,
            name: "Spanish",
        }
    ])


    const logout = async () => {
        try {
            const { error } = await supabaseClient.auth.signOut();
            if (error) throw error
        } catch (error) {
            console.error(error.message)
        }
    }
    

    return (
        <div style={{padding: "20px"}}>
            <div className='smallTile'>                
                <div>Account</div>
                <button onClick={logout}>Log Out</button>
            </div>
            <div className='smallTile'>                
                <div>Classes</div>
                {classes && classes.map((c) => (
                    <Link to={`/classes/${c?.id}`}>
                        <button>{c?.name}</button>
                    </Link>
                ))}
            </div>
        </div>
    )
}
