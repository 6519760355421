import React, { useState } from 'react'

export default function WordDisplay({wordData}) {

    const [open, setOpen] = useState(false)

    function click(e){
        e.stopPropagation();
        if(!open){
            setOpen(true)
            return
        }        
    }

    const handleMouseOver = () => {
        setOpen(true)
    }
    const handleMouseLeave = () => {
        setOpen(false)
    }

    function speakSpanishWord(e){        
        e.stopPropagation();
        
        let utteranceEs = new SpeechSynthesisUtterance(wordData?.es)
  
        // Set the voice to spanish
        let voice = speechSynthesis.getVoices().find(voice => voice.lang === "es-ES")
        if(voice)
          utteranceEs.voice = voice
    
        // Speak it
        let synth = window.speechSynthesis
        synth.speak(utteranceEs)
    }

  return (
    <div 
        className={'wordDisplay'} 
        onClick={click}
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseLeave}
    >        
        <div>{wordData.es}</div>
        {open &&
            <>
                <div className='closeButton' onClick={()=>setOpen(false)}>x</div>
                <div>{wordData.en}</div>
                <div>{wordData.note}</div>
                {wordData.imageUrl && <img src={wordData.imageUrl}></img>}
                <button onClick={speakSpanishWord}>Speak ES</button>
            </>
        }
    </div>
  )
}