import React, { useState } from 'react'
import WordCard from './WordCard'
import './WordDisplay.css'

export default function WordDisplay({wordData : wordDataParam, pauseKeyListener}) {

    const [open, setOpenState] = useState(false)
    // This can be updated when the input supbase updates a field so the new data shows now
    const [wordData, setWordData] = useState(wordDataParam)

    function setOpen(newOpenState){
        if(pauseKeyListener) pauseKeyListener.current = newOpenState
        setOpenState(newOpenState)
    }

    return (
        <> 
            {!open
                ? 
                <div onClick={()=>setOpen(true)} className='wordDisplay'>{wordData.es}</div>
                : 
                <WordCard wordData={wordData} close={ ()=>setOpen(false)} setWordData={setWordData}/>
            }                    
        </>
    )
}