import React, { useEffect, useState } from 'react'
import { auth } from '../../../Global/firebaseInit'
import { supabaseClient } from '../../../Global/supabaseinit'
export default function AuthPage() {

    const [message, setMessage] = useState()
    
            
    // example@example.com
    // password
    async function attemptSignIn(){
        console.log("attemptSignIn")
        setMessage()

        const emailInputValue = document.getElementById("emailInput")?.value
        const passInputValue = document.getElementById("passInput")?.value
        console.log(emailInputValue)
        console.log(passInputValue)
        
        if(!emailInputValue || !emailInputValue.includes("@")){
            setMessage(`Please enter a valid username or email (6 characters mininmum)`)
            return
        }
        else if(!passInputValue || passInputValue.length < 6){
            setMessage(`Please enter a valid password (6 characters mininmum)`)            
            return
        }
        
        const { data, error } = await supabaseClient.auth.signInWithPassword({
            email: emailInputValue,
            password: passInputValue,
        })

        if(error)
            setMessage(error.message)
    }

    async function handleSignOut() {
        const { error } = await supabaseClient.auth.signOut()
        if (error) {
            setMessage(error.message)
        }
    }

    const [resettingPassword, setResettingPassword] = useState()
    function toggleResettingPassword(){
        setMessage()
        setResettingPassword(!resettingPassword)
    }
    function submittedUsernameForPassReset(){
        setMessage()
        let emailInputValue = document.getElementById("emailInput")?.value
        if(!emailInputValue || emailInputValue.length < 6)
            setMessage(`Please enter a valid username or email (6 characters mininmum)`)
        else
            setMessage("If an account exists for "+emailInputValue+" an email with password reset instructions has been sent.")
    }

  return (
    <div className='page authPage'>
        <div className='studentPortalColumn'>
            {/* <div className='studentPortalRow studentSigninLogo'>
                <img src={logo}></img>
            </div> */}
            <div className='studentPortalRow studentPortalInputFieldsBox'>
                {!resettingPassword ? 
                <>
                    <div className='studentPortalInputFieldsBoxTitle'>
                        Student Portal Sign In
                    </div>
                    <div>
                        <input placeholder='Username or Email' id='emailInput'></input>
                    </div>
                    <div>
                        <input placeholder='Password' type='password' id='passInput'></input>
                    </div>
                    {message && <div className='signInMessage'>
                        <pre>
                            {message}
                        </pre>
                    </div>}
                    <div>
                        <button onClick={attemptSignIn}>Sign In</button>
                        <button onClick={handleSignOut}>Sign Out</button>
                    </div>
                    <div className='loginBottomText'>
                        Forgot password? <a onClick={toggleResettingPassword} className='inlineLink'>Reset it here.</a>
                    </div>
                </>
                :
                <>
                    <div className='studentPortalInputFieldsBoxTitle'>
                        Password Reset
                    </div>
                    <div>
                        <input placeholder='Username / Email' id='emailInput'></input>
                    </div>
                    <div className='signInMessage'>
                        <pre>
                            {message}
                        </pre>
                    </div>
                    <div>
                        <button onClick={submittedUsernameForPassReset}>Reset Password</button>
                    </div>
                    <div className='loginBottomText'>
                        Remembered password? <a onClick={toggleResettingPassword} className='inlineLink'>Sign In Here.</a>
                    </div>
                </>    
                }
            </div>
        </div>
    </div>
  )
}